export const logins = [
  { name: "username", text: "Email", type: "email" },
  { name: "password", text: "Password", type: "password" },
];

export const registers = [
  { name: "name", text: "Nama Lengkap", type: "text" },
  { name: "nik", text: "NIK", type: "text" },
  { name: "address", text: "Alamat", type: "text" },
  { name: "rt", text: "RT", type: "text" },
  { name: "rw", text: "RW", type: "text" },
  { name: "village_name", text: "Desa/Kelurahan", type: "text" },
  { name: "sub_district_name", text: "Kecamatan", type: "text" },
  { name: "email", text: "Email", type: "email" },
  { name: "password", text: "Password", type: "password" },
];
