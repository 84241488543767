import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";

export const BackIcon = () => {
  const history = useHistory();

  return (
    <BiArrowBack
      onClick={history.goBack}
      style={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        cursor: "pointer",
      }}
    />
  );
};
