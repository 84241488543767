import { SubServiceTypeActionType } from "../action-types";
import { ActionSubServiceType } from "../actions";

const initialState = { loading: false, data: [], error: "" };

const reducer = (state: any = initialState, action: ActionSubServiceType) => {
  switch (action.type) {
    case SubServiceTypeActionType.FETCH_SUB_SERVICE_TYPES:
      return { ...state, loading: true };
    case SubServiceTypeActionType.FETCH_SUB_SERVICE_SUCCESS:
      return { loading: false, data: action.payload, error: "" };
    case SubServiceTypeActionType.FETCH_SUB_SERVICE_ERROR:
      return { loading: false, data: action.payload, error: "" };
    default:
      return state;
  }
};

export default reducer;
