export const numberWithCommas = (x: string | number) => {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const removeFalsyObjectValue = (obj: any) => {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};
