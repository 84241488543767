import { TYPE_1, TYPE_2 } from "../service.constants";

export const service1 = [
  { name: "name", text: "Nama", type: "text" },
  { name: "nik", text: "NIK", type: "text" },
  { name: "place_of_birth", text: "Tempat Lahir", type: "text" },
  { name: "date_of_birth", text: "Tanggal Lahir", type: "date" },
  { name: "address", text: "Alamat", type: "text" },
];

export const service2 = [
  { name: "rt", text: "RT", type: "text" },
  { name: "rw", text: "RW", type: "text" },
  { name: "village_name", text: "Kel/Desa", type: "text" },
  { name: "sub_district_name", text: "Kecamatan", type: "text" },
];

export const serviceType = [
  {
    name: "type",
    text: "Tipe",
    type: "radio",
    options: [
      { id: "type-1", name: "type", value: "1", label: TYPE_1 },
      { id: "type-2", name: "type", value: "0", label: TYPE_2 },
    ],
  },
  { name: "name", text: "Nama Layanan", type: "text" },
  {
    name: "status",
    text: "Status",
    type: "radio",
    options: [
      { id: "1", name: "status", value: "1", label: "Aktif" },
      { id: "2", name: "status", value: "0", label: "Tidak Aktif" },
    ],
  },
];

export const subServiceType = [
  { name: "name", text: "Nama Sub Layanan", type: "text" },
  {
    name: "status",
    text: "Status",
    type: "radio",
    options: [
      { id: "1", name: "status", value: "1", label: "Aktif" },
      { id: "2", name: "status", value: "0", label: "Tidak Aktif" },
    ],
  },
];
