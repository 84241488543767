export const DANGER = "#FF5B5C";
export const DANGER_LIGHT = "#FFDEDE";
export const WARNING = "#FDAC41";
export const WARNING_LIGHT = "#FFEED9";
export const SUCCESS = "#00CFDD";
export const SUCCESS_LIGHT = "#CCF5F8";
export const PRIMARY = "#7367F0";
export const PRIMARY_LIGHT = "#BAB5EB";

export const Colors = [
  { DANGER, DANGER_LIGHT },
  { WARNING, WARNING_LIGHT },
  { SUCCESS, SUCCESS_LIGHT },
  { PRIMARY, PRIMARY_LIGHT },
];

export const ArrayColors = [
  [DANGER, DANGER_LIGHT],
  [WARNING, WARNING_LIGHT],
  [SUCCESS, SUCCESS_LIGHT],
  [PRIMARY, PRIMARY_LIGHT],
];
