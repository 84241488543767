import {
  SERVICE_STATUS_ACCEPTED,
  SERVICE_STATUS_FINISHED,
  SERVICE_STATUS_PROCESSED,
  SERVICE_STATUS_REJECTED,
} from "shared/constants";

export const assemblyParameters = (params: any) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const assemblyStatus = (status: string) => {
  if (status === SERVICE_STATUS_PROCESSED) {
    return "Diproses";
  } else if (status === SERVICE_STATUS_ACCEPTED) {
    return "Diterima";
  } else if (status === SERVICE_STATUS_FINISHED) {
    return "Selesai";
  } else if (status === SERVICE_STATUS_REJECTED) {
    return "Ditolak";
  }
};
