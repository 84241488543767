import axios from "axios";
import { ILogin, ILoginResponse } from "../shared/interfaces";

const API_URL = process.env.REACT_APP_API_URL;

export const authHeader = () => {
  const getUserFromLocalStorage = localStorage.getItem("user");
  let user: ILoginResponse = getUserFromLocalStorage
    ? JSON.parse(atob(getUserFromLocalStorage))
    : null;
  if (user?.access_token) {
    return { headers: { Authorization: `Bearer ${user.access_token}` } };
  }
  return {};
};

export const LoginService = async (dataLogin: ILogin) => {
  try {
    const res = await axios.post(`${API_URL}/auth/login`, dataLogin);

    if (res.data) {
      localStorage.setItem("user", btoa(JSON.stringify(res.data)));
    }
    return res.data;
  } catch (error: any) {
    return error.message;
  }
};
