export const terbilang = (number: number, setAudios: any) => {
  if (number < 12) {
    const audioSingleNumber = new Audio(`/assets/audio/${number}.MP3`);
    setAudios((prev: any) => [...prev, audioSingleNumber]);
  } else if (number >= 12 && number < 20) {
    const audioSingleNumber = new Audio(`/assets/audio/${number - 10}.MP3`);
    const audioAfterSingle = new Audio(`/assets/audio/belas.MP3`);
    setAudios((prev: any) => [...prev, audioSingleNumber, audioAfterSingle]);
  } else if (number >= 20 && number < 100) {
    const audioSingleNumber = new Audio(
      `/assets/audio/${parseInt(String(number / 10).substr(0, 1))}.MP3`
    );
    const audioAfterSingle = new Audio(`/assets/audio/puluh.MP3`);
    const audioAfterSingle2 = new Audio(`/assets/audio/${number % 10}.MP3`);
    setAudios((prev: any) => [
      ...prev,
      audioSingleNumber,
      audioAfterSingle,
      audioAfterSingle2,
    ]);
  } else if (number >= 100 && number < 200) {
    const audioSeratus = new Audio(`/assets/audio/100.MP3`);
    setAudios((prev: any) => [...prev, audioSeratus]);
    terbilang(number % 100, setAudios);
  } else if (number >= 200 && number < 1000) {
    const audioSingleNumber = new Audio(
      `/assets/audio/${parseInt(String(number / 100).substr(0, 1))}.MP3`
    );
    const audioSeratus = new Audio(`/assets/audio/ratus.MP3`);
    setAudios((prev: any) => [...prev, audioSingleNumber, audioSeratus]);
    terbilang(number % 100, setAudios);
  } else if (number >= 1000 && number < 2000) {
    const audioSeribu = new Audio(`/assets/audio/seribu.mp3`);
    setAudios((prev: any) => [...prev, audioSeribu]);
    terbilang(number - 1000, setAudios);
  } else if (number >= 2000 && number < 10000) {
    const audioSingleNumber = new Audio(
      `/assets/audio/${parseInt(String(number / 1000).substr(0, 1))}.MP3`
    );
    const audioSeribu = new Audio(`/assets/audio/ribu.MP3`);
    setAudios((prev: any) => [...prev, audioSingleNumber, audioSeribu]);
    terbilang(number % 1000, setAudios);
  } else if (number >= 10000 && number < 100000) {
    const audioSeribu = new Audio(`/assets/audio/ribu.MP3`);
    terbilang(parseInt(String(number / 100).substr(0, 2)), setAudios);
    setAudios((prev: any) => [...prev, audioSeribu]);
    terbilang(number % 1000, setAudios);
  } else if (number >= 100000 && number < 1000000) {
    const audioSeribu = new Audio(`/assets/audio/ribu.MP3`);
    terbilang(parseInt(String(number / 1000).substr(0, 3)), setAudios);
    setAudios((prev: any) => [...prev, audioSeribu]);
    terbilang(number % 1000, setAudios);
  }
};
