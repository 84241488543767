import { CLEAR_ERRORS, LOADING_UI, SET_ERRORS } from "../action-types";

const initialState = { loading: false, errors: null };

const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_ERRORS:
      return { ...state, loading: false, errors: action.payload };
    case CLEAR_ERRORS:
      return { ...state, loading: false, errors: null };
    case LOADING_UI:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default reducer;
