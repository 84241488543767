import axios from "axios";
import { assemblyParameters } from "../../shared/functions";
import { SET_ERRORS, SubServiceTypeActionType } from "../action-types";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchSubServiceTypes = (params: any) => (dispatch: any) => {
  const paramsQuery = assemblyParameters(params);
  dispatch({ type: SubServiceTypeActionType.FETCH_SUB_SERVICE_TYPES });
  axios
    .get(`${API_URL}/sub-service-type?${paramsQuery}`)
    .then((res: any) => {
      dispatch({
        type: SubServiceTypeActionType.FETCH_SUB_SERVICE_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_ERRORS, payload: err.message });
    });
};

export const fetchSubServiceType =
  (sub_service_type_id: number) => (dispatch: any) => {
    dispatch({ type: SubServiceTypeActionType.FETCH_SUB_SERVICE_TYPES });
    axios
      .get(`${API_URL}/sub-service-type/${sub_service_type_id}`)
      .then((res: any) => {
        dispatch({
          type: SubServiceTypeActionType.FETCH_SUB_SERVICE_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: SET_ERRORS, payload: err.message });
      });
  };
