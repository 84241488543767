import axios from "axios";
import { assemblyParameters } from "shared/functions";
import { ServiceQueueActionType, SET_ERRORS } from "store/action-types";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchServiceQueue = (params: any) => (dispatch: any) => {
  const paramsQuery = assemblyParameters(params);
  axios
    .get(`${API_URL}/service-queues?${paramsQuery}`)
    .then((res: any) => {
      dispatch({
        type: ServiceQueueActionType.FETCH_SERVICE_QUEUE_SUCCESS,
        payload: res.data?.data,
      });
    })
    .catch((err) => dispatch({ type: SET_ERRORS, payload: err.message }));
};

export const fetchServiceQueueById = (id: number) => (dispatch: any) => {
  axios
    .get(`${API_URL}/service-queues/${id}`)
    .then((res: any) => {
      dispatch({
        type: ServiceQueueActionType.FETCH_SERVICE_QUEUE_SUCCESS,
        payload: res.data?.data,
      });
    })
    .catch((err) => dispatch({ type: SET_ERRORS, payload: err.message }));
};
