import { UserActionType } from "../action-types";

const tokenLocal = localStorage.getItem("token");
const userLocal = localStorage.getItem("user");
const initialState = {
  authenticated: !!tokenLocal,
  credentials: tokenLocal ? JSON.parse(atob(tokenLocal)) : "",
  data: userLocal ? JSON.parse(atob(userLocal)) : {},
  loading: false,
};

const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case UserActionType.SET_AUTHENTICATED:
      return { ...state, authenticated: true };
    case UserActionType.SET_UNAUTHENTICATED:
      return { ...state, authenticated: false };
    case UserActionType.SET_USER_LOGIN:
      return {
        authenticated: true,
        loading: false,
        ...action.payload,
      };
    case UserActionType.SET_USER:
      return {
        authenticated: true,
        loading: false,
        credentials: state.credentials,
        data: action.payload.data,
      };
    case UserActionType.LOADING_USER:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default reducer;
