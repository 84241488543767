export const settings = [
  { name: "name", text: "Nama", type: "text" },
  { name: "nik", text: "NIK", type: "text" },
  { name: "place_of_birth", text: "Tempat Lahir", type: "text" },
  { name: "date_of_birth", text: "Tanggal Lahir", type: "date" },
  { name: "address", text: "Alamat", type: "text" },
  { name: "rt", text: "RT", type: "text" },
  { name: "rw", text: "RW", type: "text" },
  { name: "email", text: "Email", type: "email" },
  {
    name: "password",
    text: "Password",
    type: "password",
    placeholder: "Kosongkan jika tidak ingin merubah!",
  },
];
