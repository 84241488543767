import axios from "axios";
import { authHeader } from ".";
import { assemblyParameters } from "../shared/functions";
import { IQueryService } from "../shared/interfaces";

const API_URL = process.env.REACT_APP_API_URL;

export const getAllServices = async (params: IQueryService) => {
  try {
    const paramsQuery = assemblyParameters(params);
    const res = await axios.get(
      `${API_URL}/services?${paramsQuery}`,
      authHeader()
    );
    return res.data;
  } catch (error: any) {
    return error;
  }
};

export const saveServices = async (service: any) => {
  try {
    const res = await axios.post(`${API_URL}/services`, service, authHeader());
    return res.data;
  } catch (error) {
    return error;
  }
};

export const updateServices = async (service: any) => {
  try {
    const res = await axios.patch(
      `${API_URL}/services/${service.id}`,
      service,
      authHeader()
    );
    return res.data;
  } catch (error) {
    return error;
  }
};
