// import { Colors } from "../enums";

import { ArrayColors } from "../constants/colors.constant";

export const randomObjectKey = (colors: typeof ArrayColors) => {
  // const keys = Object.keys(color).filter(
  //   (k) => !(Math.abs(Number.parseInt(k)) + 1)
  // );
  // const selectedColor = keys[Math.floor(Math.random() * keys.length)];
  const selectedColor = colors[Math.floor(Math.random() * colors.length)];
  return selectedColor;
};
