import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from "axios";

import { USER_ADMIN, USER_INHABITANT, USER_STAFF } from "shared/constants";

const ServiceTypeDetailPage = React.lazy(
  () => import("pages/setting/service-type-detail")
);
const ServiceTypePage = React.lazy(() => import("pages/setting/service-type"));
const UpdateProfilePage = React.lazy(
  () => import("pages/setting/update-profile")
);
const ArchivePage = React.lazy(() => import("pages/archive/archive"));
const DashboardPage = React.lazy(() => import("pages/dashboard"));
const FinishPage = React.lazy(() => import("pages/finish"));
const LoginPage = React.lazy(() => import("pages/login"));
const SettingPage = React.lazy(() => import("pages/setting/setting"));
const ProtectedRoute = React.lazy(() => import("./protected.route"));
const SubArchivePage = React.lazy(() => import("pages/archive/sub-archive"));
const TableArchivePage = React.lazy(
  () => import("pages/archive/table-archive")
);
const AddQueueOfflinePage = React.lazy(
  () => import("pages/queue/add-queue-offline")
);
const DisplayPage = React.lazy(() => import("pages/display"));
const RegisterPage = React.lazy(() => import("pages/register"));
const InSubServicePage = React.lazy(() => import("pages/in-sub-service"));
const InHistoryPage = React.lazy(() => import("pages/in-history"));
const PrivacyPage = React.lazy(() => import("pages/privacy"));
const AddQueueOnlinePage = React.lazy(
  () => import("pages/queue/add-queue-online")
);
const ShowQueueOnlinePage = React.lazy(
  () => import("pages/queue/show-queue-online")
);
const ShowQueueOfflinePage = React.lazy(
  () => import("pages/queue/show-queue.offline")
);

const MainRouter = () => {
  const tokenLocal = localStorage.getItem("token");
  const token = tokenLocal ? `Bearer ${JSON.parse(atob(tokenLocal))}` : "";
  axios.defaults.headers.common["Authorization"] = token;

  return (
    <Suspense fallback={<>Loading...</>}>
      <Router>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/display" component={DisplayPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <ProtectedRoute
            exact
            path="/"
            roles={[USER_ADMIN, USER_STAFF, USER_INHABITANT]}
            component={DashboardPage}
          />
          <ProtectedRoute
            exact
            path="/finish"
            roles={[USER_ADMIN, USER_STAFF]}
            component={FinishPage}
          />
          <ProtectedRoute
            exact
            path="/archive"
            roles={[USER_ADMIN, USER_STAFF]}
            component={ArchivePage}
          />
          <ProtectedRoute
            path="/archive/:service_type_id/sub/:sub_service_type_id"
            roles={[USER_ADMIN, USER_STAFF]}
            component={TableArchivePage}
          />
          <ProtectedRoute
            path="/archive/:id"
            roles={[USER_ADMIN, USER_STAFF]}
            component={SubArchivePage}
          />
          <ProtectedRoute
            exact
            path="/setting"
            roles={[USER_ADMIN, USER_STAFF, USER_INHABITANT]}
            component={SettingPage}
          />
          <ProtectedRoute
            path="/setting/service-type/:service_type_id"
            roles={[USER_ADMIN, USER_STAFF, USER_INHABITANT]}
            component={ServiceTypeDetailPage}
          />
          <ProtectedRoute
            path="/setting/service-type"
            roles={[USER_ADMIN, USER_STAFF, USER_INHABITANT]}
            component={ServiceTypePage}
          />
          <ProtectedRoute
            path="/setting/update-profile"
            roles={[USER_ADMIN, USER_STAFF, USER_INHABITANT]}
            component={UpdateProfilePage}
          />
          <ProtectedRoute
            path="/queue/add-offline"
            roles={[USER_ADMIN, USER_STAFF]}
            component={AddQueueOfflinePage}
          />
          <ProtectedRoute
            path="/queue/show/:id"
            roles={[USER_ADMIN, USER_STAFF]}
            component={ShowQueueOfflinePage}
          />
          <ProtectedRoute
            path="/in/sub-service/:service_type_id"
            roles={[USER_ADMIN, USER_STAFF, USER_INHABITANT]}
            component={InSubServicePage}
          />
          <ProtectedRoute
            path="/in/history"
            roles={[USER_ADMIN, USER_STAFF, USER_INHABITANT]}
            component={InHistoryPage}
          />
          <ProtectedRoute
            path="/add-queue/:sub_service_type_id"
            roles={[USER_ADMIN, USER_STAFF, USER_INHABITANT]}
            component={AddQueueOnlinePage}
          />
          <ProtectedRoute
            path="/show-queue/:queue_id"
            roles={[USER_ADMIN, USER_STAFF, USER_INHABITANT]}
            component={ShowQueueOnlinePage}
          />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default MainRouter;
