import { ServiceTypeActionType } from "../action-types";
import { ActionServiceType } from "../actions";

const initialState = { loading: false, data: [], error: "" };

const reducer = (state: any = initialState, action: ActionServiceType) => {
  switch (action.type) {
    case ServiceTypeActionType.FETCH_SERVICE_TYPES:
      return { ...state, loading: true };
    case ServiceTypeActionType.FETCH_SERVICE_TYPE_SUCCESS:
      return { loading: false, data: action.payload, error: "" };
    case ServiceTypeActionType.FETCH_SERVICE_TYPE_ERROR:
      return { loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default reducer;
