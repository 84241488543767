import { ServiceChecklistActionType } from "../action-types";
import { ActionServiceChecklist } from "../actions";

const initialState = { loading: false, data: [], error: "" };

const reducer = (state: any = initialState, action: ActionServiceChecklist) => {
  switch (action.type) {
    case ServiceChecklistActionType.FETCH_SERVICE_CHECKLIST:
      return { ...state, loading: true };
    case ServiceChecklistActionType.FETCH_SERVICE_CHECKLIST_SUCCESS:
      return { loading: false, data: action.payload, error: "" };
    default:
      return state;
  }
};

export default reducer;
