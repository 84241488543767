import { getAllServiceTypes } from "../../services/service-type.service";
import { ServiceTypeActionType, SET_ERRORS } from "../action-types";

export const fetchServiceTypes = (params: any) => {
  return (dispatch: any) => {
    dispatch(fetchServiceTypeRequest());
    getAllServiceTypes(params)
      .then((res: any) => {
        dispatch(fetchServiceTypeSuccess(res.data))})
      .catch((err: any) => {
        dispatch({ type: SET_ERRORS, payload: err.message });
      });
  };
};

export const fetchServiceTypeRequest = () => {
  return { type: ServiceTypeActionType.FETCH_SERVICE_TYPE_REQUEST };
};

export const fetchServiceTypeSuccess = (service_types: any) => {
  return {
    type: ServiceTypeActionType.FETCH_SERVICE_TYPE_SUCCESS,
    payload: service_types,
  };
};

export const fetchServiceTypeError = (err: any) => {
  return { type: ServiceTypeActionType.FETCH_SERVICE_TYPE_ERROR, payload: err };
};
