import { ServiceActionType } from "../action-types";
import { ActionService } from "../actions";

const initialState = { loading: false, data: [], error: "" };

const reducer = (state: any = initialState, action: ActionService) => {
  switch (action.type) {
    case ServiceActionType.FETCH_SERVICES:
      return { ...state, loading: true };
    case ServiceActionType.FETCH_SERVICE_SUCCESS:
      return { loading: false, data: action.payload, error: "" };
    case ServiceActionType.FETCH_SERVICE_ERROR:
      return { loading: false, data: [], error: action.payload };
    case ServiceActionType.SAVE_SERVICE:
      return state;
    default:
      return state;
  }
};

export default reducer;
