import Pusher from "pusher-js";

// Pusher.logToConsole = true;
const pusher_key = process.env.REACT_APP_PUSHER_KEY || "";

const pusher = new Pusher(pusher_key, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

export const pusherStart = () => {
  return pusher;
};

export const pusherStop = () => {
  pusher.disconnect();
};
