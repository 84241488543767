import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { Provider } from "react-redux";

import { store } from "./store";
import common_id from "./translations/id/common.json";
import common_en from "./translations/en/common.json";
import MainRouter from "./components/routers";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "id",
  resources: {
    id: {
      common: common_id,
    },
    en: {
      common: common_en,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <MainRouter />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
