export const USER_ADMIN = 'admin';
export const USER_HEAD_DEPARTMENT = 'head_department';
export const USER_STAFF = 'user_staff';
export const USER_INHABITANT = 'inhabitant';

export type USER_ROLE =
  | typeof USER_ADMIN
  | typeof USER_HEAD_DEPARTMENT
  | typeof USER_STAFF
  | typeof USER_INHABITANT;

export const USER_TYPE = [
  USER_ADMIN,
  USER_HEAD_DEPARTMENT,
  USER_STAFF,
  USER_INHABITANT,
];

export enum Role {
  USER_ADMIN = 'admin',
  USER_HEAD_DEPARTMENT = 'head_department',
  USER_STAFF = 'user_staff',
  USER_INHABITANT = 'inhabitant',
}

export const VERIFIED = 'verified';
export const BLOCKED = 'blocked';

export const SINGLE = 'single'; // lajang
export const MARRIED = 'married'; // menikah
export const WIDOW = 'widow'; // janda
export const WIDOWER = 'widower'; // duda

export const MALE = 'M';
export const FEMALE = 'F';