import axios from "axios";
import { ServiceChecklistActionType, SET_ERRORS } from "../action-types";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchServiceChecklist =
  (sub_service_type_id: number) => (dispatch: any) => {
    dispatch({ type: ServiceChecklistActionType.FETCH_SERVICE_CHECKLIST });
    const api_url = `${API_URL}/service-checklist/sub-service-type/${sub_service_type_id}`;
    axios
      .get(api_url)
      .then((res: any) => {
        dispatch(fetchServiceChecklistSuccess(res.data.data));
      })
      .catch((err) => {
        dispatch({ type: SET_ERRORS, payload: err.message });
      });
  };

export const fetchServiceChecklistSuccess = (services_checklist: any) => {
  return {
    type: ServiceChecklistActionType.FETCH_SERVICE_CHECKLIST_SUCCESS,
    payload: services_checklist,
  };
};
