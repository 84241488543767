import axios from "axios";
import {
  CLEAR_ERRORS,
  LOADING_UI,
  SET_ERRORS,
  UserActionType,
} from "../action-types";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUser = (params: any) => {
  return (dispatch: any) => {};
};

export const loginUser = (dataLogin: any, history?: any) => (dispatch: any) => {
  dispatch({ type: LOADING_UI });
  axios
    .post(`${API_URL}/auth/login`, dataLogin)
    .then((res: any) => {
      const token = `Bearer ${res.data.access_token}`;
      axios.defaults.headers.common["Authorization"] = token;
      localStorage.setItem(
        "token",
        btoa(JSON.stringify(res.data.access_token))
      );
      localStorage.setItem("user", btoa(JSON.stringify(res.data.data)));
      dispatch({ type: UserActionType.SET_USER_LOGIN, payload: res.data });
      dispatch({ type: CLEAR_ERRORS });
      history.push("/");
    })
    .catch((err) => {
      dispatch({ type: SET_ERRORS, payload: err.message });
    });
};

export const registerUser = async (dataReg: any) => (dispatch: any) => {
  dispatch({ type: LOADING_UI });
  axios
    .post(`${API_URL}/users`, dataReg)
    .then((res: any) => {
      const dataLogin = {
        username: dataReg.email,
        password: dataReg.password,
      };
      loginUser(dataLogin);
    })
    .catch((err) => {
      dispatch({ type: SET_ERRORS, payload: err.message })
    });
};

export const getUserData = (user_id: number) => (dispatch: any) => {
  axios.get(`${API_URL}/users/${user_id}`).then((res) => {
    if (res) {
      const newUser: any = res.data;
      localStorage.setItem("user", btoa(JSON.stringify(newUser?.data)));
      dispatch({ type: UserActionType.SET_USER, payload: res.data });
    }
  });
};

export const logoutUser = () => (dispatch: any) => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: UserActionType.SET_UNAUTHENTICATED });
  window.location.href = "/login";
};

export const updateUser =
  (user_id: number, payload: any) => (dispatch: any) => {
    dispatch({ type: LOADING_UI });
    dispatch({ type: UserActionType.UPDATE_USER });
    axios
      .patch(`${API_URL}/users/${user_id}`, payload)
      .then(() => {
        dispatch({ type: CLEAR_ERRORS });
        dispatch(getUserData(user_id));
      })
      .catch(() => {
        dispatch(logoutUser());
      });
  };
