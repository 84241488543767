import axios from "axios";
import { authHeader } from ".";
import { assemblyParameters } from "../shared/functions";

const API_URL = process.env.REACT_APP_API_URL;

export const getAllServiceTypes = async (params: any) => {
  try {
    const paramsQuery = assemblyParameters(params);
    const res = await axios.get(
      `${API_URL}/service-type?${paramsQuery}`,
      authHeader()
    );
    return res.data;
  } catch (error) {
    return error;
  }
};
