import { ServiceQueueActionType } from "store/action-types";
import { ActionServiceQueue } from "store/actions/service-queue.action";

const initialState = { loading: false, data: [], error: "" };

const reducer = (state: any = initialState, action: ActionServiceQueue) => {
  switch (action.type) {
    case ServiceQueueActionType.FETCH_SERVICE_QUEUES:
      return { ...state, loading: true };
    case ServiceQueueActionType.FETCH_SERVICE_QUEUE_SUCCESS:
      return { loading: false, data: action.payload, error: "" };
    case ServiceQueueActionType.FETCH_SERVICE_QUEUE_ERROR:
      return { loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default reducer;
