import { CLEAR_ERRORS, ServiceActionType, SET_ERRORS } from "../action-types";
// import { Dispatch } from "redux";
// import { ActionService } from "../actions";
import { getAllServices, saveServices, updateServices } from "../../services";
import { logoutUser } from ".";

export const fetchServices = (params: any) => {
  return (dispatch: any) => {
    dispatch(fetchServiceRequest());
    getAllServices(params).then((res) => {
      if (res?.statusCode === 401) {
        dispatch(logoutUser());
      } else if (res?.statusCode === 200) {
        dispatch(fetchServiceSuccess(res.data));
      } else {
        dispatch({ type: SET_ERRORS, payload: res.message });
      }
    });
  };
};

export const fetchServiceRequest = () => {
  return { type: ServiceActionType.FETCH_SERVICE_REQUEST };
};

export const fetchServiceSuccess = (services: any) => {
  return { type: ServiceActionType.FETCH_SERVICE_SUCCESS, payload: services };
};

export const fetchServiceError = (err: any) => {
  return { type: ServiceActionType.FETCH_SERVICE_ERROR, payload: err };
};

export const saveService = (service: any) => {
  return (dispatch: any) => {
    saveServices(service)
      .then(() => dispatch({ type: CLEAR_ERRORS }))
      .catch((err) => dispatch({ type: SET_ERRORS, payload: err.message }));
  };
};

export const updateService = (service: any) => {
  return (dispatch: any) => {
    updateServices(service)
      .then(() => dispatch({ type: CLEAR_ERRORS }))
      .catch((err: { message: any }) =>
        dispatch({ type: SET_ERRORS, payload: err.message })
      );
  };
};

// export const addService = (payload: any) => {
//   return (dispatch: Dispatch<ActionService>) => {
//     dispatch({ type: ServiceActionType.ADD, payload });
//   };
// };

// export const removeService = (payload: any) => {
//   return (dispatch: Dispatch<ActionService>) => {
//     dispatch({ type: ServiceActionType.REMOVE, payload });
//   };
// };
