import { combineReducers } from "redux";

import serviceReducer from "./service.reducer";
import serviceTypeReducer from "./service-type.reducer";
import subServiceTypeReducer from "./sub-service-type.reducer";
import serviceChecklistReducer from "./service-checklist.reducer";
import userReducer from "./user.reducer";
import uiReducer from "./ui.reducer";
import serviceQueueReducer from "./service-queue.reducer";

const reducers = combineReducers({
  service: serviceReducer,
  serviceQueue: serviceQueueReducer,
  serviceType: serviceTypeReducer,
  subServiceType: subServiceTypeReducer,
  serviceChecklist: serviceChecklistReducer,
  user: userReducer,
  ui: uiReducer,
});

export default reducers;

export type State = ReturnType<typeof reducers>;
